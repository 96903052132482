import * as firebase from "firebase";
import "firebase/database";

let config = {
    apiKey: "AIzaSyC42YEV2XfoR-KlEd-Ng34zw-i6aYgE89U",
  authDomain: "djivan-75125.firebaseapp.com",
  databaseURL: "https://djivan-75125-default-rtdb.firebaseio.com",
  projectId: "djivan-75125",
  storageBucket: "djivan-75125.appspot.com",
  messagingSenderId: "176767483607",
  appId: "1:176767483607:web:d06574e0b0955d3eccba92",
  measurementId: "G-J8R1LNY5W1"
};

firebase.initializeApp(config);

export default firebase.database();